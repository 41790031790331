.dzu-dropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 120px;
    margin: 0 auto;
    position: relative;
    transition: all .15s linear;
    border-radius: 4px;
    background: #FFFFFF;
    border: 1px dashed #D9D9D9;
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
  
  .dzu-dropzoneActive {
    background-color: #DEEBFF;
    border-color: #2484FF;
  }
  
  .dzu-dropzoneDisabled {
    opacity: 0.5;
  }
  
  .dzu-dropzoneDisabled *:hover {
    cursor: unset;
  }
  
  .dzu-input {
    display: none;
  }
  
  .dzu-inputLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    cursor: pointer;
    
    
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 22px;

    letter-spacing: 0.46px;
    text-transform: uppercase;

    color: #0078F0;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 8px 0px;
  }
  
  .dzu-inputLabelWithFiles {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    padding: 0 14px;
    min-height: 32px;
    background-color: #E6E6E6;
    color: #2484FF;
    border: none;
    font-family: 'Helvetica', sans-serif;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    margin-top: 20px;
    margin-left: 3%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
  }
  
  .dzu-previewContainer {
    padding: 18px 34%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    /* width: 100%; */
    min-height: 60px;
    z-index: 1;
    box-sizing: border-box;
    
  }
  
  .dzu-previewStatusContainer {
    display: flex;
    align-items: center;
    position: absolute;
    right: 1em;
    top: 1em;
  }
  
  .dzu-previewFileName {
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
  }
  
  .dzu-previewImage {
    width: auto;
    min-height: 80px;
    min-width: 180px;
    max-height: 80px;
    max-width: 180px;
    border-radius: 4px;
  }
  
  .dzu-previewButton {
    background-size: 14px 14px;
    background-position: center;
    background-repeat: no-repeat;
    width: 14px;
    height: 14px;
    cursor: pointer;
    opacity: 0.9;
    margin: 0 0 2px 10px;
    color: #0078F0;
  }
  
  .dzu-submitButtonContainer {
    margin: 24px 0;
    z-index: 1;
  }
  
  .dzu-submitButton {
    padding: 0 14px;
    min-height: 32px;
    background-color: #2484FF;
    border: none;
    border-radius: 4px;
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #FFF;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
  }
  
  .dzu-submitButton:disabled {
    background-color: #E6E6E6;
    color: #333333;
    cursor: unset;
  }

  @media (max-width: 600px) {
    .dzu-previewContainer{
       padding: 18px 22%;
    }
   
 }