
/* CSS Calendario */
.DateRangePicker {
    position: relative;
    display: inline-block;
    z-index: 100;
    font-size: 10px;
    font-stretch: semi-condensed;
}
.DateRangePickerInput__withBorder {
    /* border-radius: 5px; */
    border: none;
    border-bottom: 1px solid rgba(22, 22, 22, 0.867);
    margin-top: 15px;
    margin-right:5px ;
    font-family: Cambay,sans-serif;
    font-stretch: semi-condensed;
    font-size: 10px;

}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background: #0078F0;
    border: 1px solid #0078F0;
    color: #fff;
    font-size: 10px;

}

.CalendarDay__selected_span {
    background: #0078F0;
    border: 1px solid #0078F0;
    color: #fff;
    font-family: Cambay,sans-serif;
    font-stretch: semi-condensed;
    font-size: 10px;

}

.CalendarDay__hovered_span, .CalendarDay__hovered_span:hover {
    background: #0078F0;
    border: 1px solid #0078F0;
    color: #fff;
    font-size: 10px;


}

.CalendarDay__selected_span:active, .CalendarDay__selected_span:hover {
    background: #0078F0;
    border: 1px solid #0078F0;
    color: #fff;
    font-size: 10px;

}